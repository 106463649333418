/* CSS GERAL */
a.disabled {
  pointer-events: none;
  cursor: default;
}

.text-underline-hover {
  text-decoration: none;
}

.text-underline-hover:hover {
  text-decoration: underline;
}

.text-justify {
  text-align: justify;
}

.uppercase-first-letter::first-letter {
  text-transform: uppercase;
}

.image-container {
  height: 214px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.image-container-slider {
  max-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.image-container img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.card-hover div.col {
  opacity: 1;
  transition: opacity 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  border-radius: 3px;
  cursor: pointer;
}

.card-hover:hover div.col {
  opacity: 0.9;
  box-shadow: 0px 0px 3px 3px #ffc107;
  border-radius: 3px;
}
